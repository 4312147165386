import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CookieService } from '../cookies/cookie.service';
import { TimeService } from '../time/time.service';
import { Store } from '@ngrx/store';
import { LoyaltyActions } from '../../store/loyalty/actions/loyalty.actions';
import { Socket, io } from 'socket.io-client';
import {
  SuccessItemPurchase,
  tradableBalance,
  userRewardedStatistics,
  userTurnover,
} from '../../interfaces/interfaces';
@Injectable({
  providedIn: 'root',
})
export class LoyaltyService {
  // chnage URL to your API in NGINX and in angular proxy.conf.json
  // private readonly apiUrl = 'https://staging.coduluz.com/api/v2/loyalty/';

  private socket!: Socket;
  constructor(
    private http: HttpClient,
    private cookieService: CookieService,
    private timeService: TimeService,
    private store: Store
  ) {}

  //Auth
  loyaltyLogin(userId: string, password: string, email: string, maskedEmail: string): Observable<HttpResponse<any>> {
    return this.http.post<any>(`/auth/auth/login`, { userId, password, email, maskedEmail }, { observe: 'response' });
  }
  loyaltyRegistration(userId: string, password: string, email: string, maskedEmail: string) {
    return this.http.post(`/auth/auth/sign-up`, { userId, password, email, maskedEmail }, { observe: 'response' });
  }
  getLoyaltyAccesToken(refreshToken: string) {
    const headers = new HttpHeaders().set('Refresh_token', refreshToken);
    return this.http.get(`/auth/auth/access-token`, { headers, observe: 'response' });
  }
  //Rakeboost
  getRakeBoost() {
    return this.http.post(`/api/v2/loyalty/boosts/get`, {});
  }

  //Balance
  getTradableBalance(): Observable<tradableBalance> {
    return this.http.post<tradableBalance>(`/api/v2/loyalty/tradable-balance/get-tradable-balance`, null);
  }
  getUserRewardedStatistics(): Observable<userRewardedStatistics> {
    return this.http.post<userRewardedStatistics>(
      `/api/v2/loyalty/tradable-balance/get-user-rewarded-statistics`,
      null
    );
  }
  //turnover
  getUserTurnover(): Observable<userTurnover> {
    return this.http.post<userTurnover>(`/api/v2/loyalty/turnover/get-user-turnover`, null);
  }
  //cashback
  getUserCashback() {
    return this.http.post(`/api/v2/loyalty/cashback/get-user-cashback`, null);
  }
  takeUserCashback(type: number) {
    return this.http.post(`/api/v2/loyalty/cashback/collect-user-cashback`, { type });
  }
  //Rakeback
  collectRakeback() {
    return this.http.post(`/api/v2/loyalty/rake-balance/collect-rakeback`, null);
  }
  getRakebackBalance() {
    return this.http.post(`/api/v2/loyalty/rake-balance/get-rake-balance`, null);
  }
  //wellbet
  collectWellbet() {
    return this.http.post(`/api/v2/loyalty/welle-bet-balance/collect-welle-bet`, null);
  }
  getWellbetBalance() {
    return this.http.post(`/api/v2/loyalty/welle-bet-balance/get-welle-bet-balance`, null);
  }

  getUserBonusRate() {
    return this.http.post(`/api/v2/loyalty/user/get-user-bonus-rates`, null);
  }
  //Ranks
  getUserRank() {
    return this.http.get(`/api/v2/loyalty/rank/get-user-rank`);
  }
  getCollectedUserRanks() {
    return this.http.post(`/api/v2/loyalty/rank/get-collected-user-ranks`, null);
  }
  getRanks() {
    return this.http.get(`/api/v2/loyalty/rank/get-ranks`);
  }
  collectRank(rankId: number) {
    return this.http.post(`/api/v2/loyalty/rank/collect-rank`, { rankId });
  }
  //calendar
  getCalendarActions(startDate: Date, endDate: Date) {
    return this.http.post(`/api/v2/loyalty/calendar/get-user-calendar-actions`, {
      startDate,
      endDate,
    });
  }
  collectCalendarAction(id: number) {
    return this.http.post(`/api/v2/loyalty/calendar/collect-action`, {
      id,
    });
  }
  //shop
  getAllShopItems() {
    return this.http.get(`/api/v2/loyalty/shop/all-items`);
  }
  buyItemFromStore(itemId: number, userId: string) {
    return this.http.post(`/api/v2/loyalty/shop/buy-item`, {
      itemId: itemId,
      login: userId,
    });
  }
  activeBonusEvent(itemId: number, userId: string) {
    return this.http.post(`/api/v2/loyalty/shop/actived-bonus-event`, {
      itemId: itemId,
      login: userId,
    });
  }
  getShopHistory() {
    return this.http.get(`/api/v2/loyalty/shop/history`);
  }

  getRankColor(rankName: string) {
    if (rankName?.includes('cabin_boy')) {
      return 'text-darkBlueBgLight';
    } else if (rankName?.includes('boatswain')) {
      return 'text-textBlueLight';
    } else if (rankName?.includes('admiral')) {
      return 'text-[#075985]';
    } else if (rankName?.includes('captain')) {
      return 'text-brownGoldLight';
    } else if (
      rankName?.includes('legend_of_the_seas') ||
      rankName?.includes('triton') ||
      rankName?.includes('poseidon')
    ) {
      return 'text-blackTextLight';
    }
    return '';
  }
  setLoyaltyCookies(accessToken: string, refreshToken: string) {
    sessionStorage.setItem('loyalty_access_token', accessToken);
    sessionStorage.setItem('loyalty_refresh_token', refreshToken);
    this.store.dispatch(LoyaltyActions['[Loyalty]GetCombinedLoyaltyData']());
    this.store.dispatch(LoyaltyActions['[Loyalty]GetWellBetAndReefback']());
  }
  checkLoyaltyCookies(): boolean {
    const hasAccessToken = sessionStorage.getItem('loyalty_access_token') !== null;
    const hasRefreshToken = sessionStorage.getItem('loyalty_refresh_token') !== null;
    return hasAccessToken && hasRefreshToken;
  }
  deleteLoyaltyTokens() {
    sessionStorage.removeItem('loyalty_refresh_token');
    sessionStorage.removeItem('loyalty_access_token');
  }

  connect(url: string): Observable<any> {
    this.socket = io(url, {
      transports: ['websocket'],
    });
    return new Observable((observer) => {
      this.socket.on('connect', () => {
        observer.next('connected');
      });

      this.socket.on('disconnect', () => {
        observer.next('disconnected');
      });

      this.socket.on('send-event', (message) => {
        observer.next(message);
      });

      this.socket.on('error', (err) => {
        observer.error(err);
      });

      return () => {
        this.socket.disconnect();
      };
    });
  }
  sendSocketUserId(userId: string): void {
    this.socket.emit('saveUserId', userId);
  }
  sendSocketShopBonus(item: SuccessItemPurchase): void {
    this.socket.emit('shopBonusActivated', item);
  }
  sendSocketActiveBonus(isActiveBonus: boolean, userId: string): void {
    if (!userId) return;
    this.socket.emit('is-bonus', {
      isActiveBonus,
      userId,
    });
  }
  disconnect(): void {
    this.socket.disconnect();
  }
  addDataFromSocket(res: any) {
    switch (res.eventKey) {
      case 'UPDATE_WELLE_BET_BALANCE':
        res.data.nextAvailableAt += 'Z';
        this.store.dispatch(LoyaltyActions['[Loyalty]SetWellBetSuccess']({ data: res.data }));
        break;
      case 'UPDATE_RAKE_BALANCE':
        res.data.nextAvailableAt += 'Z';
        this.store.dispatch(LoyaltyActions['[Loyalty]SetReefbackSuccess']({ data: res.data }));
        break;
      case 'UPDATE_TRADABLE_BALANCE':
        this.store.dispatch(LoyaltyActions['[Loyalty]SetTradableBalanceSuccess']({ data: res.data }));
        break;
      case 'UPDATE_REWARDED_STATISTICS':
        this.store.dispatch(LoyaltyActions['[Loyalty]SetRewardedStatisticsSuccess']({ data: res.data }));
        break;
      case 'UPDATE_TURNOVER_BALANCE':
        this.store.dispatch(LoyaltyActions['[Loyalty]SetUserTurnoverSuccess']({ data: res.data }));
        break;
    }
  }

  filtreBoost(boost: any) {
    const now = new Date();
    const activeBoosts = boost.filter((b: any) => new Date(b.activeTo) >= now);
    if (activeBoosts.length === 0) {
      return null;
    }
    const closestBoost = activeBoosts.reduce((closest: any, current: any) => {
      const currentDiff = Math.min(
        Math.abs(new Date(current.activeFrom).getTime() - now.getTime()),
        Math.abs(new Date(current.activeTo).getTime() - now.getTime())
      );

      const closestDiff = Math.min(
        Math.abs(new Date(closest.activeFrom).getTime() - now.getTime()),
        Math.abs(new Date(closest.activeTo).getTime() - now.getTime())
      );

      return currentDiff < closestDiff ? current : closest;
    });

    return closestBoost;
  }
}
